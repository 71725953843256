.textCount{
    background-color: white;
    padding: 15px;
    border-radius: 25px;
    font-size: 1.8rem;
    position: absolute;
    bottom: 1rem;
    left: 4em;
    right: 4em;
    display: inline-block;
    width: 24em;
    max-width: 100%;
    margin: 0 auto;
    z-index: 4;
}

.count{
    color: hsl(141, 71%, 48%);
    margin-right: 10px
}