  .Modal {
      position: absolute;
      top: 10%;
      left: 30%;
      right: 40px;
      bottom: 40px;
      width:750px;
      height:750px;
      background-color: rgba(233, 0, 0, 0);
    }

  .Overlay {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 999;
      background-color: rgba(0, 0, 0, 0.404);
  }